import { useState } from 'react';

import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import { useAwaitFullScreenConfirmCancelModal } from '../../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../../ConfirmCancelModalContext/ModalWrapper';

const EXAMPLES = [
  {
    title: 'Course Introduction',
    description: `A slide to introduce the corporate training program.

- Title: Welcome to the Corporate Training Program
- Subtitle: Empowering Our Team for Success
- Author: John Doe
`,
  },
  {
    title: 'Product Features',
    description: `A slide to introduce the new features of the smartphone.
  
1. Display: 6.5-inch OLED display with 120Hz refresh rate for smoother visuals.
2. Camera: Triple-lens system with 108MP main sensor for stunning photography.
3. Battery: Long-lasting 5000mAh battery with fast charging support.
4. Connectivity: 5G enabled for faster internet speeds and better connectivity.
5. Security: Advanced facial recognition and fingerprint sensor for enhanced security.
  `,
  },
  {
    title: 'Project Milestone',
    description: `A slide to introduce the project milestone.
    
- Phase 1: Initial planning and resource allocation
- Phase 2: Development and testing
- Phase 3: Deployment and feedback collection
- Phase 4: Final review and project closure
- Timeline with key dates and deliverables
    `,
  },
];

export function CodeGeneratorModal(props: {
  userRequest: string;
  onCancel: () => void;
  onGenerate: (description: string) => void;
}) {
  const { userRequest, onCancel, onGenerate } = props;

  const [description, setDescription] = useState(userRequest);

  const handleGenerate = useLiveCallback(() => {
    onGenerate(description);
  });

  return (
    <div className='w-full h-full flex flex-col items-center gap-8'>
      <div className='font-bold text-2xl'>Make a slide with AI</div>

      <div className='w-full flex flex-col gap-2'>
        <label className='font-bold'>Describe your slide</label>
        <textarea
          className={`w-full h-40 field m-0 py-3`}
          placeholder={'A slide to...'}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className='flex flex-col gap-2 mt-2'>
          <span className='text-icon-gray text-sms'>Or try a sample:</span>
          <div className='flex gap-2'>
            {EXAMPLES.map((example, index) => (
              <button
                type='button'
                key={index}
                className='btn bg-[#4b4b4b] hover:brightness-110 text-icon-gray text-xs px-3 py-1 rounded'
                onClick={() => setDescription(example.description)}
              >
                {example.title}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className='flex justify-center items-center gap-2'>
        <button
          type='button'
          className='btn-secondary w-40 py-2'
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type='button'
          className='btn-primary w-40 py-2'
          onClick={handleGenerate}
        >
          Generate
        </button>
      </div>
    </div>
  );
}

export function useTriggerCodeGenerator() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback(
    async (props: {
      userRequest: string;
      onGenerate: (description: string) => void;
    }) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            borderStyle='gray'
            containerClassName='w-180'
            innerClassName='p-10'
          >
            <CodeGeneratorModal
              userRequest={props.userRequest}
              onCancel={() => {
                p.internalOnCancel();
              }}
              onGenerate={(description) => {
                props.onGenerate(description);
                p.internalOnConfirm();
              }}
            />
          </ModalWrapper>
        ),
      });
    }
  );
}
