import capitalize from 'lodash/capitalize';
import { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import { proxy, useSnapshot } from 'valtio';

import { type CodeCanvasBlock } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { useLiveAsyncCall } from '../../../../../hooks/useAsyncCall';
import { apiService } from '../../../../../services/api-service';
import { assertExhaustive } from '../../../../../utils/common';
import { type GameEditorStore } from '../../../../Game/GameEditorStore';
import { CodeCanvasUtils } from '../../../../GameV2/blocks/CodeCanvas/utils';
import { Loading } from '../../../../Loading';
import { DialogueEditor } from '../../../../VoiceOver/Dialogue/DialogueEditor';
import { useTrainingSlideEditor } from '../../hooks';
import { type TrainingSlideEditorProps } from '../../types';
import { useTriggerCodeGenerator } from './CodeGenerator';
import { CodeCanvasEditorUtils } from './utils';
const tabs = ['preview', 'code'] as const;

type Tab = (typeof tabs)[number];

type State = {
  activeTab: Tab;
};

const state = proxy<State>({
  activeTab: 'preview',
});

export function CodeCanvasBlockEditorTabs() {
  const { activeTab } = useSnapshot(state);

  useEffect(() => {
    // Reset state on unmount
    return () => {
      state.activeTab = 'preview';
    };
  }, []);

  return (
    <div className='p-2 flex items-center gap-2.5 text-white'>
      {tabs.map((tab) => (
        <button
          type='button'
          key={tab}
          className={`
            w-25 h-8 bg-secondary rounded-lg
            border ${activeTab === tab ? 'border-white' : 'border-transparent'}
          `}
          onClick={() => {
            state.activeTab = tab;
          }}
        >
          {capitalize(tab)}
        </button>
      ))}
    </div>
  );
}

function CodeTab(props: TrainingSlideEditorProps<CodeCanvasBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const {
    data: code,
    isLoading,
    mutate,
  } = useSWRImmutable(
    block.fields.generationEndTime
      ? `${block.id}-${block.fields.generationEndTime}-code`
      : null,
    async () => {
      if (!block.fields.generationEndTime) {
        return '';
      }

      const fetchedCode = await CodeCanvasEditorUtils.FetchCode(
        block.id,
        block.fields.generationEndTime
      );
      return fetchedCode;
    }
  );

  const { call: handleBlur } = useLiveAsyncCall(async (next: string) => {
    if (next === code) return;

    await CodeCanvasUtils.UploadCode(block.id, next);

    onChange('generationStartTime', new Date().toISOString());
    onBlur('generationStartTime', new Date().toISOString());
    onChange('generationEndTime', new Date().toISOString());
    onBlur('generationEndTime', new Date().toISOString());

    mutate(next, {
      revalidate: false,
    });
  });

  if (isLoading) {
    return (
      <div className='absolute inset-0 z-50 flex items-center justify-center bg-lp-black-004'>
        <Loading text='Loading Code...' />
      </div>
    );
  }

  return (
    <div className='absolute inset-0 z-50 flex flex-col'>
      <textarea
        className='w-full h-full field p-2 m-0 flex-grow disabled:opacity-50'
        key={block.fields.generationEndTime}
        defaultValue={code}
        onBlur={(e) => handleBlur(e.target.value)}
        disabled={block.fields.generating}
      />
    </div>
  );
}

function PreviewTab(props: TrainingSlideEditorProps<CodeCanvasBlock>) {
  const { block } = props;

  const src = CodeCanvasUtils.GetCodeS3Url(
    block.id,
    block.fields.generationEndTime
  );
  if (!block.fields.generationStartTime) {
    return (
      <div className='w-full h-full flex items-center justify-center text-icon-gray'>
        No code provided. Edit in the "Code" tab or generate content.
      </div>
    );
  }

  return (
    <div className='absolute inset-0'>
      {src && (
        <iframe src={src} className='w-full h-full' title='Code Preview' />
      )}
      {block.fields.generating && (
        <div className='absolute inset-0 bg-lp-black-004 flex items-center justify-center'>
          <Loading text='This might take a while to generate, please be patient...' />
        </div>
      )}
    </div>
  );
}

export function CodeCanvasBlockEditor(
  props: TrainingSlideEditorProps<CodeCanvasBlock>
) {
  const { activeTab } = useSnapshot(state);

  switch (activeTab) {
    case 'preview':
      return <PreviewTab {...props} />;
    case 'code':
      return <CodeTab {...props} />;
    default:
      assertExhaustive(activeTab);
  }
}

export function CodeCanvasBlockEditorUnder(
  props: TrainingSlideEditorProps<CodeCanvasBlock>
) {
  const { block } = props;

  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='flex flex-col gap-5 text-white'>
      <DialogueEditor
        key={block.id}
        offlineRendering={false}
        value={block.fields.dialogue}
        enabledMarkTypes={['trigger', 'tutor-question']}
        importEnabled={true}
        onChange={(value) => {
          onChange('dialogue', value);
          onBlur('dialogue', value);
        }}
        disabled={block.fields.generating}
      />
    </div>
  );
}

export function CodeCanvasBlockSidebarEditor(
  props: TrainingSlideEditorProps<CodeCanvasBlock> & { store: GameEditorStore }
) {
  const { block, store } = props;
  const triggerModal = useTriggerCodeGenerator();
  const { onChange } = useTrainingSlideEditor(props);
  // const { data: personalities } = usePersonalities();
  // const defaultPersonalityId = findDefaultPersonality(personalities);

  const { call: generate } = useLiveAsyncCall(async () => {
    triggerModal({
      userRequest: block.fields.userRequest ?? '',
      onGenerate: async (description) => {
        onChange('userRequest', description);
        onChange('generationStartTime', new Date().toISOString());
        onChange('generationEndTime', null);
        onChange('generating', true);

        const resp = await apiService.block.generateCodeCanvas(block.id, {
          userRequest: description,
        });
        store.blockEditorStore.replaceBlock(fromAPIBlockTypes(resp.data.block));
      },
    });
  });

  return (
    <div className='flex flex-col gap-5 text-white'>
      <button
        type='button'
        className='btn-primary w-full py-2'
        disabled={block.fields.generating}
        onClick={() => generate()}
      >
        {block.fields.generating
          ? 'Generating...'
          : block.fields.userRequest
          ? 'Regenerate'
          : 'Generate'}
      </button>
    </div>
  );
}
